import axios from "axios";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Footer from "../../components/Footer/Footer";
import "./home.css";
import "./profile.css";

function Home() {
    const [isVisible, setVisible] = useState(null);
    const [profile, setProfile] = useState(null);
    const [start, setStart] = useState(false);
    const navigate = useNavigate();
    const [showConfetti, setShowConfetti] = useState(false);
    const {
        watch,
        register,
        handleSubmit,
        setError,
        getValues,
        formState: { errors }
    } = useForm();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        if (start === false) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                .then((res) => {
                    setProfile(res.data.data);
                })
                .catch(() => {
                    localStorage.removeItem("user");
                    navigate("/login");
                });
        }
    }, [start]);
    useEffect(() => {
        const interval = setInterval(() => {
            axios.get(`${process.env.REACT_APP_API_URL}/notification/getnotifi`, {}).then((res) => {
                if (res?.data.data[0]) {
                    setVisible({
                        money: res?.data?.data[0]?.money.toLocaleString(),
                        id: res?.data?.data[0]?._id,
                        msg: res?.data?.data[0]?.msg
                    });
                }
            });
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let swalInst;
        const showAlert = async (data) => {
            setShowConfetti(true);
            swalInst = swal({
                title: "Xin chúc mừng !!!",
                text: `Chúc mừng quý khách mang ID ${Number(profile?.iduser)} đã trúng giải thưởng ngẫu nhiên trị giá ${data.money.toLocaleString()} VNĐ. Vui lòng liên hệ CSKH để biết thêm chi tiết.`,
                //text: data.msg,
                className: "bg-gift",
                //icon: GiftImg,
                buttons: {
                    submit: "Đồng ý"
                }
            }).then(() => {
                setShowConfetti(false);
                axios.post(`${process.env.REACT_APP_API_URL}/notification/seen`, {
                    id: data.id
                });
            });
            setVisible(false);
        };
        if (isVisible) {
            showAlert(isVisible);
        }
    }, [isVisible]);
    return (
        <>
            <div className="app1">
                {profile != null ? (
                    <>
                        <div className="header">TRUNG TÂM VIỆC LÀM HCES</div>
                        <div className="detail_id_home">
                            <div className="detail_id" style={{ position: "unset" }}>
                                <div className="item_id">ID : {Number(profile?.iduser)}</div>
                                <div style={{ fontWeight: "500" }}>
                                    Số điểm: <b>{profile.money}</b>
                                </div>
                            </div>
                            <div style={{ fontWeight: "600", padding: "30px 0px 10px", fontSize: "18px" }}>TRUNG TÂM KẾT NỐI VIỆC LÀM HCES</div>
                        </div>
                    </>
                ) : null}
                {/* <div style={{ padding: "10px" }}>
                    <Link target="_blank" to="https://trungtamhces.com/">
                        <img height={350} src={require("../../img/b1.png")} alt="" />
                    </Link>
                </div> */}
                <div style={{ padding: "0 10px 10px", float: "left", width: "100%" }}>
                    <div
                        onClick={() => {
                            localStorage.setItem("banner", "2");
                            navigate("/game3");
                        }}>
                        <img src={require("../../img/b2.png")} style={{ float: "left", width: "100%" }} />
                    </div>
                </div>
                <div style={{ padding: "0 10px 10px", float: "left", width: "100%" }}>
                    <div
                        onClick={() => {
                            localStorage.setItem("banner", "3");
                            navigate("/game3");
                        }}>
                        <img src={require("../../img/b2.jpg")} style={{ float: "left", width: "100%", height: "130px" }} />
                    </div>
                </div>
                {/* <div style={{ padding: "0 10px 10px", float: "left", width: "100%" }}>
                    <div
                        onClick={() => {
                            localStorage.setItem("banner", "4");
                            navigate("/game3");
                        }}>
                        <img src={require("../../img/b4.png")} style={{ float: "left", width: "100%" }} />
                    </div>
                </div> */}
            </div>
            {showConfetti && (
                <Confetti
                    style={{
                        width: "100vw",
                        height: "100vh",
                        maxWidth: "540px",
                        zIndex: "999999"
                    }}
                />
            )}
            <Footer profile={profile} />
        </>
    );
}
export default Home;
