import CachedIcon from "@mui/icons-material/Cached";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";

import Footer from "../../components/Footer/Footer";

import "./home.css";
import "./profile.css";

function Game1() {
    const [isVisible, setVisible] = useState(null);
    const [bet, setBet] = useState(null);
    const [profile, setProfile] = useState(null);
    const [second, setSecond] = useState(0);
    const [minute, setMinute] = useState(3);
    const [start, setStart] = useState(false);
    const [dulieunhap, setDulieunhap] = useState(new Date());
    const [update, setUpdate] = useState(0);
    const [active, setActive] = useState(null);
    const date = moment().tz("Asia/Ho_Chi_Minh");
    const currentMinute = date.minutes();
    const currentSecond = date.seconds();
    const [total, setTotal] = useState(null);
    const [showConfetti, setShowConfetti] = useState(false);
    const [choose, setChoose] = useState(null);
    const [tabActive, setTabActive] = useState(1);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        if (!start) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                .then((res) => {
                    setProfile(res.data.data);
                })
                .catch(() => {
                    localStorage.removeItem("user");
                    navigate("/login");
                });
            axios.get(`${process.env.REACT_APP_API_URL}/bet1/get`).then((res) => {
                setBet(res.data.data);
                setDulieunhap(moment(res.data.data.createdAt).tz("Asia/Ho_Chi_Minh").toDate());
                setStart(true);
            });
            axios
                .get(`${process.env.REACT_APP_API_URL}/bet1/getallbet`, {})
                .then((res) => {
                    setTotal(res.data.data);
                })
                .catch(() => setTotal(null));
        }
    }, [start]);

    useEffect(() => {
        const interval = setInterval(() => {
            axios.get(`${process.env.REACT_APP_API_URL}/notification/getnotifi`, {}).then((res) => {
                if (res?.data.data[0]) {
                    setVisible({
                        money: res?.data?.data[0]?.money.toLocaleString(),
                        id: res?.data?.data[0]?._id,
                        msg: res?.data?.data[0]?.msg
                    });
                }
            });
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let swalInst;
        const showAlert = async (data) => {
            setShowConfetti(true);
            swalInst = swal({
                title: "Xin chúc mừng !!!",
                text: `Chúc mừng quý khách mang ID ${Number(profile?.iduser)} đã trúng giải thưởng ngẫu nhiên trị giá ${data.money.toLocaleString()} VNĐ. Vui lòng liên hệ CSKH để biết thêm chi tiết.`,
                className: "bg-gift",
                buttons: {
                    submit: "Đồng ý",
                },
            }).then(() => {
                setShowConfetti(false);
                axios.post(`${process.env.REACT_APP_API_URL}/notification/seen`, {
                    id: data.id,
                });
            });
            setVisible(false);
        };
        if (isVisible) {
            showAlert(isVisible);
        }
    }, [isVisible]);

    useEffect(() => {
        let curTime_second = Math.floor(60 - (moment().tz("Asia/Ho_Chi_Minh") - dulieunhap) / 1000);

        let myTimeout;

        if (
            currentMinute === moment(dulieunhap).minute() &&
            currentSecond === moment(dulieunhap).second()
        ) {
            setStart(true);
            setSecond(second - 1);
            return () => {
                clearTimeout(myTimeout);
            };
        } else if (curTime_second < 60 && curTime_second >= 0) {
            setSecond(curTime_second % 60);
            setMinute((curTime_second - (curTime_second % 60)) / 60);
            setStart(true);
            return () => {
                clearTimeout(myTimeout);
            };
        } else {
            myTimeout = setTimeout(() => {
                setUpdate(update + 1);
            }, 500);
        }
    }, [update, dulieunhap]);

    useEffect(() => {
        let curTime_second = Math.floor(60 - (moment().tz("Asia/Ho_Chi_Minh") - dulieunhap) / 1000);
        let myTimeout;
        if (start) {
            setSecond(curTime_second % 60);
            setMinute(Math.floor(curTime_second / 60));

            if (curTime_second > 60 || curTime_second <= 0) {
                setStart(false);
                setMinute(1);
                setSecond(0);
            } else {
                myTimeout = setTimeout(() => {
                    setSecond(prevSecond => prevSecond - 1);
                }, 1000);
            }
        }
        return () => {
            clearTimeout(myTimeout);
        };
    }, [second, start, dulieunhap]);

    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);

    const onChoose = (e) => {
        setChoose(e);
        setActive(e);
        setShowPopup(true);
    };

    const onSubmit = async (data) => {
        if (quantity == 0) {
            swal("Thông báo", "Vui lòng nhập số tiền", "info");
            return;
        }
        if (!choose) {
            swal("Thông báo", "Vui lòng chọn ô", "warning");
            return;
        }
        const formDate = {
            id: bet._id,
            money: quantity,
            bet: choose,
            type_game: localStorage.getItem("banner") || 3,
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/history1/choose`, formDate)
            .then((res) => {
                swal("Thành công", "Nhập thành công", "success");
                setStart(false);
                setShowPopup(false);
            })
            .catch((res) => swal("error", res.response.data.message, "error"));
    };

    const handleClose = () => {
        setShowPopup(false);
    };

    const handleBackdropClick = (event) => {
        if (event.target === event.currentTarget) {
            setShowPopup(false);
        }
    };

    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };

    const [quantity, setQuantity] = useState(100);

    const handleSetValue = (value) => {
        setQuantity(value);
    };

    const resetForm = () => {
        setQuantity(100);
        setActive(null);
        setShowPopup(false);
    }

    return (
        <>
            <div className="app1">
                <div style={{ background: "#aed8d4", padding: "10px 0" }}>
                    <div data-v-69b3b4a1="" data-v-0f9d40fc="" className="lottery-header theme-main-bg theme-header-bottom" data-v-435dc89a="">
                        <Link to="/">
                            {" "}
                            <div data-v-69b3b4a1="" className="back-btn"></div>
                        </Link>
                        <section data-v-82095748="" data-v-0f9d40fc="" className="lottery-timer theme-main-bg-5 theme-after-border-color-1" data-v-69b3b4a1="">
                            <div data-v-82095748="" className="label">
                                <p data-v-82095748="" className="issue theme-color-white">
                                    ID: {profile && (Number(profile.iduser))}
                                </p>
                            </div>
                            <div data-v-82095748="" className="content-box">
                                <div
                                    style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginBottom: "-8px"
                                    }}>
                                    {" "}
                                    {localStorage.getItem("banner") == "3" ? "Mã quay thưởng: " : "Dữ liệu: "}
                                    <span className="sid">{bet ? bet.id_bet : "----"}</span>{" "}
                                </div>
                                <div data-v-82095748="" className="content theme-color-white">
                                    <span data-v-82095748=""></span>
                                    <span data-v-82095748="" className="time">
                                        00
                                    </span>
                                    <span data-v-82095748="" className="time shengtime1">
                                        0{minute}
                                    </span>
                                    <span data-v-82095748="" className="time shengtime2">
                                        {second < 10 ? "0" : ""}
                                        {second}
                                    </span>
                                </div>
                            </div>
                        </section>
                        <div style={{ width: "100px", textAlign: "right", padding: "0 10px" }}>
                            <div className="info-icon" onClick={openPopup}>
                                <InfoIcon />
                            </div>
                        </div>
                    </div>
                    <section data-v-4a0db828="" data-v-0f9d40fc="" className="lottery-result-box" data-v-435dc89a="">
                        <div data-v-4a0db828="" className={`lottery-result ${localStorage.getItem("banner") == "3" ? "bg-pink" : ""}`}>
                            <div data-v-4a0db828="" className="label">
                                <p data-v-4a0db828="" className="issue theme-color-white">
                                    {localStorage.getItem("banner") == "3" ? "Mã quay thưởng: " : "Dữ liệu: "}
                                    <span className="sid_ed">{total && total[0] && total[0].id_bet ? total[0].id_bet : "----"}</span>
                                </p>
                            </div>
                            <div data-v-4a0db828="" className="content">
                                {total !== null && (
                                    <ul data-v-4a0db828="" className="clear-fix list">
                                        {total && total[0] && total[0].result ? (
                                            <>
                                                <li data-v-4a0db828="" className="num-item ball i0 number1 numberlist">
                                                    {total[0].result.split(" ")[0]}
                                                </li>
                                                <li data-v-4a0db828="" className="num-item ball i1 number2 numberlist">
                                                    {total[0].result.split(" ")[1]}
                                                </li>
                                                <li data-v-4a0db828="" className="num-item ball i2 number3 numberlist">
                                                    {total[0].result.split(" ")[2]}
                                                </li>
                                                <li data-v-4a0db828="" className="num-item ball i3 number4 numberlist">
                                                    {total[0].result.split(" ")[3]}
                                                </li>
                                                <li data-v-4a0db828="" className="num-item ball i4 number5 numberlist">
                                                    {total[0].result.split(" ")[4]}
                                                </li>
                                            </>
                                        ) : (
                                            <li data-v-4a0db828="" className="">
                                                Không có dữ liệu
                                            </li>
                                        )}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </section>
                </div>
                <div className="record_bet">
                    <nav data-v-260e3e17="" data-v-0f9d40fc="" className="tab-box lottery-game-tab" data-v-435dc89a="">
                        <div data-v-260e3e17="" className="tab-item tab-item-active">
                            {localStorage.getItem("banner") == "2" ? "TRAINING 1" : localStorage.getItem("banner") == "3" ? "PHÒNG 1" : "HÀNG NGÀY"}
                        </div>
                        <div data-v-260e3e17="" className="tab-item" onClick={() => navigate("/game3")}>
                            {localStorage.getItem("banner") == "2" ? "TRAINING 2" : localStorage.getItem("banner") == "3" ? "PHÒNG 2" : "HÀNG THÁNG"}
                        </div>
                        <div data-v-260e3e17="" className="tab-item" onClick={() => navigate("/game5")}>
                            {localStorage.getItem("banner") == "2" ? "TRAINING 3" : localStorage.getItem("banner") == "3" ? "PHÒNG 3" : "HÀNG NĂM"}
                        </div>
                    </nav>
                    <div className="bet-input" style={{ borderLeft: "4px solid #387bea", padding: "10px" }}>
                        <div style={{ color: "#387bea", width: "60px" }}></div>
                        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <div
                                className={localStorage.getItem("banner") == "3" ? "bg-pink" : ""}
                                style={{
                                    background: "#328b35",
                                    color: "#fff",
                                    padding: "8px 10px",
                                    borderRadius: "5px",
                                    fontSize: "12px",
                                    fontWeight: "bold"
                                }}>
                                {localStorage.getItem("banner") == "2"
                                    ? "PHÒNG TRAINING ONLINE NHẬN FILE"
                                    : localStorage.getItem("banner") == "3"
                                        ? "PHÒNG QUAY THƯỞNG"
                                        : "LOGISTICS VÀ QUẢN LÝ CHUỖI CUNG ỨNG"}
                            </div>
                        </div>
                        <div style={{ width: "60px", textAlign: "right" }} onClick={() => window.location.reload()}>
                            <CachedIcon sx={{ fontSize: "28px" }} />
                        </div>
                    </div>
                    <ul data-v-0f9d40fc="" className="lottery-code-panel-body">
                        <li onClick={(e) => { onChoose(1); }} data-v-0f9d40fc="" className={`ybcp-num-item ${active == 1 && "active"} ${localStorage.getItem("banner") == "3" ? "border-pink" : ""}`}>
                            <span data-v-0f9d40fc="" className={`lottery-code-item-name ${localStorage.getItem("banner") == "3" ? "bg-pink" : ""}`} >
                                {localStorage.getItem("banner") == "2" ? "NHẬP" : localStorage.getItem("banner") == "3" ? "KẾT NỐI" : "NỘI ĐỊA"}
                            </span>
                        </li>
                        <li onClick={(e) => { onChoose(2); }} data-v-0f9d40fc="" className={`ybcp-num-item ${active == 2 && "active"} ${localStorage.getItem("banner") == "3" ? "border-pink" : ""}`}>
                            <span data-v-0f9d40fc="" className={`lottery-code-item-name ${localStorage.getItem("banner") == "3" ? "bg-pink" : ""}`} >
                                {localStorage.getItem("banner") == "2" ? "XUẤT" : localStorage.getItem("banner") == "3" ? "YÊU THƯƠNG" : "QUỐC TẾ"}
                            </span>
                        </li>
                        <img src={require(`../../img/banner${localStorage.getItem("banner")}.png`)} />
                    </ul>
                </div>
            </div>
            {isOpen && (
                <div className="popup-hd">
                    <div className="popup-hd-header">
                        <h3>QUY ĐỊNH</h3>
                        <div className="popup-hd-close" onClick={closePopup}>
                            &times;
                        </div>
                    </div>
                    <div className="popup-hd-content">
                        <p>
                            Hệ thống nghiêm cấm các hành vi gian lận có hành vi đặt số điểm cùng một lúc 2 ô sẽ được tính là vi phạm, Nếu bị phát hiện hệ thống có quyền thu hồi tất cả số điểm và tiền thưởng từ mã số thành viên của Quý khách khi có hành vi gian dối đặt điểm và sử dụng phần mềm hỗ trợ . Vui lòng liên hệ "CSKH" để được nhân viên kịp thời hỗ trợ tư vấn.
                        </p>
                        <p>
                            Để đảm bảo trang web được hoạt động lâu dài cũng như bắt buộc duy trì các hoạt động thuế cho doanh nghiệp và nhà nước đối với các khách hàng may mắn trúng thưởng từ Hệ Thống khi tiến hành rút điểm từ trang web theo hạn mức dưới đây.
                        </p>
                        <ul>
                            <li>Hạn mức rút điểm tài khoản từ 301-500 triệu (tương ứng với 20% phí)</li>
                            <li>Hạn mức rút điểm tài khoản trên 500 triệu (tương ứng với 30% phí)</li>
                        </ul>
                    </div>
                </div>
            )}
            {showConfetti && (
                <Confetti
                    style={{
                        width: "100vw",
                        height: "100vh",
                        maxWidth: "540px",
                        zIndex: "999999"
                    }}
                />
            )}
            {showPopup && (
                <form className="lottery-bet-input" onSubmit={handleSubmit(onSubmit)}>
                    <div>Xin vui lòng nhập {localStorage.getItem("banner") == "2" ? "dữ liệu" : localStorage.getItem("banner") == "3" ? "số liệu" : "số lượng hàng hóa"}</div>
                    <div className="chip-row">
                        <div className={`chip ${quantity == 100 ? "active" : ""}`} onClick={() => handleSetValue(100)}>
                            100
                        </div>
                        <div className={`chip ${quantity == 500 ? "active" : ""}`} onClick={() => handleSetValue(500)}>
                            500
                        </div>
                        <div className={`chip ${quantity == 1000 ? "active" : ""}`} onClick={() => handleSetValue(1000)}>
                            1000
                        </div>
                        <div className={`chip ${quantity == 10000 ? "active" : ""}`} onClick={() => handleSetValue(10000)}>
                            10000
                        </div>
                        <div className={`chip ${quantity == 20000 ? "active" : ""}`} onClick={() => handleSetValue(20000)}>
                            20000
                        </div>
                    </div>
                    <div className="bet-input">
                        <div style={{ cursor: "pointer" }} onClick={resetForm}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#555"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                style={{ position: "relative", top: "2px", margin: "0 3px 0 0" }}>
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M4 7l16 0" />
                                <path d="M10 11l0 6" />
                                <path d="M14 11l0 6" />
                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                            </svg>
                            Đóng cửa
                        </div>
                        <div>
                            <input {...register("money", { required: true })} type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} name="money" />
                        </div>
                        <div>
                            <button type="submit">Xác nhận</button>
                        </div>
                    </div>
                    <div className="bet-input" style={{ color: "#000", fontWeight: "bold" }}>
                        <div>
                            {localStorage.getItem("banner") == "3" ? "Mã quay thưởng: " : "Dữ liệu: "}
                            {bet ? bet.id_bet : "----"}</div>
                        <div>Số dư: {profile?.money}</div>
                    </div>
                </form>
            )}
            <Footer profile={profile} />
        </>
    );
}
export default Game1;
