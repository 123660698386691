import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import "./users.css";
import axios from "axios";
import { Box, Button, Container, Table, Pagination, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

function Users() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [users, setUser] = useState(null);
    const [load, setLoad] = useState(false);
    const [searched, setSearched] = useState("");
    const [profile, setProfile] = useState(null);
    const navigate = useNavigate();

    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("useradmin");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        if (load == false) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                .then((res) => {
                    setProfile(res?.data?.data);
                })
                .catch(() => {
                    localStorage.removeItem("useradmin");
                    navigate("/login");
                });
            axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getall`, {})
                .then((res) => {
                    localStorage.setItem("data", JSON.stringify(res.data.data));
                    setUser(res.data.data.reverse());
                    setLoad(true);
                })
                .then((res) => setLoad(true));
        }
    }, [load]);

    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const filteredRows = JSON.parse(localStorage.getItem("data")).filter((row) => {
                const a = row.username.toString().toLowerCase().includes(searchedVal.toString());
                const b = (Number(row.iduser)).toString().includes(searchedVal.toString());
                return a || b;
            });
            setUser(filteredRows);
        } else {
            setUser(JSON.parse(localStorage.getItem("data")));
        }
    };
    const [pages, setPage] = useState(1);

    const handleChange = (e, page) => {
        setPage(page);
    };

    if (users !== null) {
        return (
            <>
                <ThemeProvider theme={theme}>
                    <DashboardLayout>
                        {
                            <Box
                                component="main"
                                sx={{
                                    flexGrow: 1,
                                    py: 1
                                }}>
                                <Container maxWidth={false}>
                                    <div className="container_set">Danh sách thành viên</div>
                                    <div className="form_set">
                                        <Box sx={{ minWidth: 1000 }}>
                                            <TextField
                                                value={searched}
                                                onChange={(searchVal) => requestSearch(searchVal.target.value)}
                                                placeholder="Tìm kiếm"
                                                sx={{ marginBottom: "5px", paddingRight: "700px" }}
                                            />
                                            <Table sx={{ width: 1600 }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>ID</TableCell>
                                                        <TableCell>Tên tài khoản</TableCell>
                                                        <TableCell>Online</TableCell>
                                                        <TableCell>Ngày tạo</TableCell>
                                                        <TableCell>Số điểm</TableCell>
                                                        <TableCell>Admin Cộng</TableCell>
                                                        <TableCell>Admin Trừ</TableCell>
                                                        <TableCell>Admin Thưởng</TableCell>
                                                        <TableCell>Tổng đặt</TableCell>
                                                        <TableCell>Cộng/trừ Tiền</TableCell>
                                                        <TableCell>Thưởng</TableCell>
                                                        <TableCell>Xem thông tin</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {users != null ? (
                                                    <TableBody>
                                                        {/*(users.reverse()).map((item) => (*/}
                                                        {users?.slice((pages - 1) * 30, (pages - 1) * 30 + 30).map((item) => (
                                                            <>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: "600" }}>{Number(item.iduser)}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600" }}>{item.username}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600" }}>
                                                                        {item.online == true ? <span style={{ color: "#14B8A6" }}>Online</span> : "Offline"}
                                                                    </TableCell>
                                                                    <TableCell sx={{ fontWeight: "600" }}>{formatDate(new Date(item.createdAt))}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600" }}>{item.money.toLocaleString()}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600" }}>{item.adminadd.toLocaleString()}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600" }}>{item.admintru.toLocaleString()}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600" }}>{item.adminthuong.toLocaleString()}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600" }}>{item.totalbet.toLocaleString()}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600" }}>
                                                                        <form
                                                                            onSubmit={(e) => {
                                                                                e.preventDefault();
                                                                                const dataForm = {
                                                                                    id: item._id,
                                                                                    money: e.target.money.value
                                                                                };
                                                                                axios.post(`${process.env.REACT_APP_API_URL}/auth/update`, dataForm).then((res) => {
                                                                                    setUser(res.data.data);
                                                                                    setLoad(false);
                                                                                    swal("Cộng/Trừ tiền thành công!");
                                                                                });
                                                                            }}>
                                                                            <input style={{ width: "100px" }} id={item._id} name="money" type="number" />
                                                                            <Button type="submit">Xác nhận</Button>
                                                                        </form>
                                                                    </TableCell>
                                                                    <TableCell sx={{ fontWeight: "600" }}>
                                                                        <form
                                                                            onSubmit={(e) => {
                                                                                e.preventDefault();
                                                                                const dataForm = {
                                                                                    id: item._id,
                                                                                    money: e.target.money.value,
                                                                                    //msg: e.target.msg.value
                                                                                    msg: "."
                                                                                };
                                                                                axios.post(`${process.env.REACT_APP_API_URL}/auth/adminthuong`, dataForm).then((res) => {
                                                                                    setLoad(false);
                                                                                    swal("Thưởng thành công!");
                                                                                });
                                                                            }}>
                                                                            <input style={{ width: "100px" }} id={item._id} name="money" type="number" placeholder="Nhập tiền" />
                                                                            <input
                                                                                style={{
                                                                                    width: "100px",
                                                                                    display: "none"
                                                                                }}
                                                                                id={item._id}
                                                                                name="msg"
                                                                                type="text"
                                                                                placeholder="Nhập nội dung"
                                                                            />
                                                                            <Button type="submit">Xác nhận</Button>
                                                                        </form>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        sx={{
                                                                            fontWeight: "600",
                                                                            display: "flex"
                                                                        }}>
                                                                        {item.isLock == false ? (
                                                                            <Button
                                                                                onClick={() => {
                                                                                    axios
                                                                                        .post(`${process.env.REACT_APP_API_URL}/auth/lockkey`, {
                                                                                            id: item._id,
                                                                                            isLock: true
                                                                                        })
                                                                                        .then((res) => {
                                                                                            setLoad(false);
                                                                                        });
                                                                                }}>
                                                                                Khóa
                                                                            </Button>
                                                                        ) : (
                                                                            <Button
                                                                                onClick={() => {
                                                                                    axios
                                                                                        .post(`${process.env.REACT_APP_API_URL}/auth/lockkey`, {
                                                                                            id: item._id,
                                                                                            isLock: false
                                                                                        })
                                                                                        .then((res) => {
                                                                                            setLoad(false);
                                                                                        });
                                                                                }}>
                                                                                Mở
                                                                            </Button>
                                                                        )}
                                                                        <Button
                                                                            onClick={() => {
                                                                                navigate(`/admin/user/${item._id}`);
                                                                            }}>
                                                                            Xem
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        ))}
                                                    </TableBody>
                                                ) : (
                                                    <></>
                                                )}
                                            </Table>
                                        </Box>
                                    </div>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            textAlign: "center",
                                            pt: 3
                                        }}>
                                        {users != null ? <Pagination color="primary" count={Math.floor(users.length / 30) + 1} size="small" onChange={handleChange} /> : null}
                                    </Box>
                                </Container>
                            </Box>
                        }
                    </DashboardLayout>
                </ThemeProvider>
            </>
        );
    } else {
        return (
            <>
                <div>Loading...</div>
            </>
        );
    }
}
export default Users;
