import { Box, Button, Container, Pagination, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { DashboardLayout } from "../../components/dashboard-layout";
import { theme } from "../../theme";
import "./request.css";

function Request() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [data, setData] = useState(null);
    const [load, setLoad] = useState(true);
    const [profile, setProfile] = useState(null);
    const [length, setLength] = useState(0);
    const [value, setValue] = useState(true);

    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("useradmin");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [searched, setSearched] = useState("");
    const [pages, setPage] = useState(1);
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const filteredRows = JSON.parse(localStorage.getItem("data")).filter((row) => {
                const a = row?.user?.username.toString().toLowerCase().includes(searchedVal.toString());
                const b = (Number(row?.user?.iduser)).toString().includes(searchedVal.toString());
                return a || b;
            });
            setData(filteredRows);
        } else {
            setData(JSON.parse(localStorage.getItem("data")));
        }
    };

    const handleChange = (e, page) => {
        setPage(page);
    };

    useEffect(() => {
        if (load === true) {
            axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
                setProfile(res?.data?.data);
            });
            axios.get(`${process.env.REACT_APP_API_URL}/payment/rut`, {}).then((res) => {
                setData(res.data.data);
                localStorage.setItem("data", JSON.stringify(res.data.data));
                setLoad(false);
                const a = res.data.data.filter((obj) => obj.status_payment === "Pending");
                setLength(a.length);
            });
        }
    }, [load]);

    useEffect(() => {
        const interval = setInterval(() => {
            axios.get(`${process.env.REACT_APP_API_URL}/payment/rut`, {}).then((res) => {
                setData(res.data.data);
                localStorage.setItem("data", JSON.stringify(res.data.data));
                setLoad(false);
                const a = res.data.data.filter((obj) => obj.status_payment === "Pending");
                setLength(a.length);
            });
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 1
                            }}>
                            <Container maxWidth={false}>
                                <div className="container_set">
                                    Yêu cầu rút tiền <span style={{ color: "red" }}>({length})</span>
                                </div>
                                <div className="form_set">
                                    <Box sx={{ minWidth: 1000 }}>
                                        <TextField
                                            value={searched}
                                            onChange={(searchVal) => requestSearch(searchVal.target.value)}
                                            placeholder="Tìm kiếm"
                                            sx={{ marginBottom: "5px", paddingRight: "700px" }}
                                        />
                                        <Table sx={{ minWidth: 1400 }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Tài khoản</TableCell>
                                                    <TableCell>ID</TableCell>
                                                    <TableCell>Thông tin thanh toán</TableCell>
                                                    <TableCell>Nội dung</TableCell>
                                                    <TableCell>Số điểm</TableCell>
                                                    <TableCell>Trạng thái</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>Hành Động</TableCell>
                                                    <TableCell>Thời gian</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data?.slice((pages - 1) * 30, (pages - 1) * 30 + 30).map((item) => (
                                                    <>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: "600" }}>{item?.user.username}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600" }}>{Number(item?.user.iduser)}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600" }}>{item?.detail}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600" }}>{item?.content}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600" }}>{item?.money.toLocaleString()}</TableCell>
                                                            {item?.status_payment === "Pending" ? (
                                                                <TableCell>
                                                                    <Button type="text" color="warning">
                                                                        {item?.status_payment}
                                                                    </Button>
                                                                </TableCell>
                                                            ) : null}
                                                            {item?.status_payment === "Success" ? (
                                                                <TableCell>
                                                                    <Button type="text" color="success">
                                                                        {item?.status_payment}
                                                                    </Button>
                                                                </TableCell>
                                                            ) : null}
                                                            {item?.status_payment === "Deny" ? (
                                                                <TableCell>
                                                                    <Button type="text" color="error">
                                                                        {item?.status_payment}
                                                                    </Button>
                                                                </TableCell>
                                                            ) : null}
                                                            <TableCell>
                                                                <input onChange={(e) => setValue(e.target.value)} placeholder="Nội dung" />
                                                                <Button
                                                                    onClick={() => {
                                                                        const formData = {
                                                                            id: item?._id,
                                                                            status: "Accept",
                                                                            content: value
                                                                        };
                                                                        axios
                                                                            .post(`${process.env.REACT_APP_API_URL}/payment/update`, formData)
                                                                            .then((res) => {
                                                                                setLoad(true);
                                                                                setValue("");
                                                                                swal("Chấp nhận thành công");
                                                                            })
                                                                            .catch((err) => setLoad(true));
                                                                    }}
                                                                    disabled={item?.status_payment !== "Pending"}>
                                                                    Xác nhận
                                                                </Button>
                                                                <Button
                                                                    onClick={() => {
                                                                        const formData = {
                                                                            id: item?._id,
                                                                            status: "Deny",
                                                                            content: value
                                                                        };
                                                                        axios
                                                                            .post(`${process.env.REACT_APP_API_URL}/payment/update`, formData)
                                                                            .then((res) => {
                                                                                setLoad(true);
                                                                                setValue("");
                                                                                swal("Từ chối thành công");
                                                                            })
                                                                            .catch((err) => setLoad(true));
                                                                    }}
                                                                    disabled={item?.status_payment !== "Pending"}>
                                                                    Từ chối
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell sx={{ fontWeight: "600" }}>{formatDate(new Date(item?.createdAt))}</TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </div>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        pt: 3
                                    }}>
                                    {data != null ? <Pagination color="primary" count={Math.floor(data.length / 30) + 1} size="small" onChange={handleChange} /> : null}
                                </Box>
                            </Container>
                        </Box>
                    }
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}
export default Request;
